import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import './style.scss';

function Download() {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (!location?.state?.chk) history.push('/glossary/termdownload');
  }, []);

  return (
    <div className="inner">
      <div className="downloadBox">
        <div className="title">소셜벤처 창업 현장 용어사전</div>
        <div className="titBox">
          <h2>소셜벤처 창업 현장 용어사전 "용어해설 동영상" 시청</h2>
          <p className="txt">
            소셜벤처 창업 현장 용어사전의 해설 영상을 시청하실 수 있습니다.
          </p>
        </div>
        <div className="linkBox">
          <div className="youtubeLinkBox">
            {/* <div className="youtubeLink">
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/Q7vWtKzw6y8"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div> */}
            <div className="youtubeLink">
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/qnLe5i1EvCU"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div className="youtubeLink">
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/9oPg04lRJWE"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div className="youtubeLink">
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/HLEW1WXLJt4"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div className="youtubeLink">
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/EbYrUBWh2JU"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Download;
