import React,{ useState, useEffect } from 'react';
import { useHistory,Link,useParams } from 'react-router-dom';
import {isMobile} from "react-device-detect";
import axios from 'axios';
import { menus,menusImg,menusMoImg } from './data';
import './style.scss';

function Introduction() {
    const history = useHistory();
    const {type} = useParams();

    const [menuList, setMenuList] = useState(menus);
    const [menuImgList, setMenuImgList] = useState(menusImg);
    const [menusMoImgList, setMenuMoImgList] = useState(menusMoImg);
    const [data, setData] = useState({
        "title"   : "",
        "content" : "",
    });

    const view = () => {
        axios.post(`${process.env.REACT_APP_API_URL}` + '/info/view',
        {
            'type' : (type === undefined ? "0" : type)
        }).then(res => {
            setData(res?.data[type]);
        }).catch()
    }

    useEffect(() => {
        view();
    }, [type]);

    return(
        <div className="introduction">
            <div className="sidebar">
                <h1>소개</h1>
                <div className="sideBox">
                    {menuList?.map((obj, i) => {
                        return (
                            <Link key={obj.seq} to={obj.path} className={"menusTit " + (obj.seq == data?.type ? 'on' : '')}>
                                {obj.name}
                            </Link>
                        );
                    })}
                </div>
            </div>
            <div className="contBox" >
                <h2 className="contTitle">{data?.title}</h2>
                {isMobile
                ? 
                <>
                    {menusMoImgList?.map((obj, i) => {
                    return (
                        <img key={i} src={obj.src} className={"menusImg " + (obj.seq == data?.type ? 'on' : '')} style={{width: '100%', height: 'auto', marginTop: '40px'}}></img>
                    );
                    })}
                </>
                : 
                <>
                    {menuImgList?.map((obj, i) => {
                    return (
                        <img key={i} src={obj.src} className={"menusImg " + (obj.seq == data?.type ? 'on' : '')} style={{width: '100%', height: 'auto', marginTop: '40px'}}></img>
                    );
                    })}
                </>
                }
                <div className="txtBox" style={{display: 'inline-block', verticalAlign: 'top'}} 
                     dangerouslySetInnerHTML={ {__html: data?.content} } 
                />
            </div>
        </div>
    )
}

export default Introduction;