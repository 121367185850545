import React,{ useState, useEffect } from 'react';
import { useParams,useHistory } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux'
import LeftBar from '../index';
import {
    AccordionDetails,
    Typography,
    AccordionSummary ,
    Accordion
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import './style.scss';

function Faq() {
    const history = useHistory();
    const [list, setList] = useState();

    const viewList = () => {
        axios.post(`${process.env.REACT_APP_API_URL}` + '/board/view',
        {
            'categoreCd' : "2"
        }).then(res => {
            if(res?.data.list){
                setList(res.data.list);
            }
        }).catch()
    }
    
    useEffect(() => {
        viewList();
    }, []);

    return (
        <>
        <LeftBar actPage={"자주 묻는 질문"}/>
            <div className="inner">
                <div className="faqBox">
                    <h2>자주 묻는 질문</h2>
                    <div className="faqListBox">
                        {list && list.map((obj, i) => (
                            <Accordion className="faqList" key={obj.seq}>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                > 
                                <Typography className="QuestionTit"><span>Q{i+1}.</span>&nbsp;&nbsp;{obj.title}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="askTit">
                                        <span>A{i+1}.</span>&nbsp;&nbsp;
                                        <div className="inbk" 
                                            dangerouslySetInnerHTML={{__html: obj.content}}
                                        >
                                        </div>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Faq;