export const korWordData = [
    { id : 0
    , word : 'All'
    }
    ,
    { id : 1
    , word : 'ㄱ'
    }
    ,
    { id : 2
    , word : 'ㄴ'
    }
    ,
    { id : 3
    , word : 'ㄷ'
    }
    ,
    { id : 4
    , word : 'ㄹ'
    }
    ,
    { id : 5
    , word : 'ㅁ'
    }
    ,
    { id : 6
    , word : 'ㅂ'
    }
    ,
    { id : 7
    , word : 'ㅅ'
    },
    { id : 8
    , word : 'ㅇ'
    }
    ,
    { id : 9
    , word : 'ㅈ'
    }
    ,
    { id : 10
    , word : 'ㅊ'
    }
    ,
    { id : 11
    , word : 'ㅋ'
    }
    ,
    { id : 12
    , word : 'ㅌ'
    }
    ,
    { id : 13
    , word : 'ㅍ'
    }
    ,
    { id : 14
    , word : 'ㅎ'
    }
];
export const engWordData = [
    { id : 1
    , word : 'A'
    }
    ,
    { id : 2
    , word : 'B'
    }
    ,
    { id : 3
    , word : 'C'
    }
    ,
    { id : 4
    , word : 'D'
    }
    ,
    { id : 5
    , word : 'E'
    }
    ,
    { id : 6
    , word : 'F'
    }
    ,
    { id : 7
    , word : 'G'
    },
    { id : 8
    , word : 'H'
    }
    ,
    { id : 9
    , word : 'I'
    }
    ,
    { id : 10
    , word : 'J'
    }
    ,
    { id : 11
    , word : 'K'
    }
    ,
    { id : 12
    , word : 'L'
    }
    ,
    { id : 13
    , word : 'M'
    }
    ,
    { id : 14
    , word : 'N'
    }
    ,
    { id : 15
    , word : 'O'
    }
    ,
    { id : 16
    , word : 'P'
    }
    ,
    { id : 17
    , word : 'Q'
    }
    ,
    { id : 18
    , word : 'R'
    }
    ,
    { id : 19
    , word : 'S'
    }
    ,
    { id : 20
    , word : 'T'
    }
    ,
    { id : 21
    , word : 'U'
    }
    ,
    { id : 22
    , word : 'V'
    }
    ,
    { id : 23
    , word : 'W'
    }
    ,
    { id : 24
    , word : 'X'
    }
    ,
    { id : 25
    , word : 'Y'
    }
    ,
    { id : 26
    , word : 'Z'
    }
];