import { ToastContainer } from 'react-toastify';
import Gate from './components/utils/Gate';

import './App.scss';

function App() {
  return (
      <div className="App">
          <Gate/>
          <ToastContainer/>
      </div>
  );
}

export default App;
