import React from 'react';
import Header from '../layout/Header';
import Body from '../layout/Body';
import Footer from '../layout/Footer';
import Introduction from '../Menus/Introduction';
import News from '../Menus/News';
import Notice from '../Menus/News/Notice';
import Question from '../Menus/News/Question';
import Faq from '../Menus/News/Faq';
import TermSearch from '../Menus/Terms/TermSearch';
import TermSearchDetail from '../Menus/Terms/TermSearch/Detail';
import TermDownload from '../Menus/Terms/TermDownload';
import Download from '../Menus/Terms/TermDownload/Download';
import PDFDownload from '../Menus/Terms/TermDownload/PDFDownload';
import Application from '../Menus/Terms/TermDownload/Application';

import { Switch, Route } from 'react-router-dom';

function Gate() {
  return (
    <div className="gate">
        <Header/>
        <Switch>
            {/* Main */}
            <Route path="/" exact component={Body} />

            {/* Introduction */}
            <Route path="/introduction/" exact component={Introduction} />
            <Route path="/introduction/:type" exact component={Introduction} />

            {/* Terms */}
            <Route path="/glossary/termsearch" exact component={TermSearch} />
            <Route path="/glossary/termsearch/:pk" exact component={TermSearchDetail} />
            <Route path="/glossary/termdownload" exact component={TermDownload} />
            <Route path="/glossary/termdownload/download"  component={Download} />
            <Route path="/glossary/termdownload/pdfdownload"  component={PDFDownload} />
            <Route path="/glossary/termdownload/application"  component={Application} />

            {/* News */}
            <Route path="/news" exact component={News} />
            <Route path="/news/notice" exact component={Notice} />
            <Route path="/news/notice/:pk" exact component={Notice} />
            <Route path="/news/question" exact component={Question} />
            <Route path="/news/faq" component={Faq} />
        </Switch>
        <Footer/>       
    </div>
  );
}

export default Gate;
