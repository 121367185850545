import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import './style.scss';

function TermPDFDownload() {
  const history = useHistory();

  return (
    <div className="inner">
      <div className="termPDFDownloadBox">
        <h1>소셜벤처 창업 현장 용어사전</h1>
        <p className="tit">소셜벤처 창업 현장 용어사전(PDF) 다운로드</p>
        <div className="txtBox">
          <p>
            소셜벤처 창업 현장 용어들을 한 눈에 확인할 수 있도록,
            <br />
            '소셜벤처 창업 현장 용어사전(PDF)'가 제작되었습니다.
            <br />
            <br />
            <span>아래 다운로드 버튼을 통해, 지금 바로 만나보세요!</span>
          </p>
          <a href="/pdf/aaa.pdf" download="소셜벤처창업현장용어사전">
            <div className="PDFDownloadBtn">PDF 다운로드</div>
          </a>
        </div>
        <div className="downloadPage">
          <p className="tit">'용어 해설 동영상 보기' 신청 페이지로 이동합니다.</p>
          <div>
            <div className="txt">
              *성함, 이메일주소 등 개인정보제공이용동의 후 용어사전(PDF)의
              <br />
              이해도를 높여줄 용어해설 동영상도 함께 시청해보세요!
            </div>
            <div
              className="applyBtn"
              onClick={() => {
                history.push('/glossary/termdownload/application');
              }}
            >
              신청하기
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TermPDFDownload;
