import React,{ useState, useEffect } from 'react';
import { useHistory,useParams } from 'react-router-dom';
import {isMobile} from "react-device-detect";
import axios from 'axios';

import './style.scss';

function Detail(props) {
    const history = useHistory();
    const {pk} = useParams();
    const [data, setData] = useState();
        
    const view = () => {
        axios.post(`${process.env.REACT_APP_API_URL}` + '/board/view',
        {
            'key'        : pk,
            'categoreCd' : "1"
        }).then(res => {
            if(res?.data.list){
                setData(res.data.list[0]);
            }
        }).catch()
    }

    useEffect(() => {
        view();
    }, []);

    return (
        <div className="BoardViewPost">
            <div className="BoardPost maxWid">
                <div className="boardMid mar100">
                    <h4 className="mar15" style={isMobile ? {fontSize:"1.1em"}: {}}>{data?.title}</h4>
                    <hr/>
                    <label className="boardInfo">작성일자 : {data?.creAt}<b></b> &nbsp;&nbsp;/&nbsp;&nbsp; 작성자 : 관리자<b></b></label>
                    <div className="boardContact mar_30" 
                        dangerouslySetInnerHTML={{__html: data?.content}}
                    >
                    </div>
                    <hr/>
                    <div className="backBtn" onClick={()=>{history.push("/news/notice")}}>목록으로 돌아가기</div>
                </div>
            </div>
        </div>
    );
}

export default Detail;