import React,{ useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";

import SwiperCore, { Navigation} from 'swiper';
import "swiper/css";
import "swiper/css/navigation"

import './style.scss';

SwiperCore.use([Navigation]);

function Footer() {
    const history = useHistory();

    return (
        <div className="footer">
            <div className="inner">
               <div className="imgLogoBox">
                    <div className="imgBox2">
                        <img src="/images/footerImg_2.png"/>
                    </div>
                    <div className="imgBox1">
                        <img src="/images/footerImg_1.png"/>
                    </div>
               </div>
                <div className="txtBox">
                    <div className="top">
                        <p className="lfTxt">EY한영 [07241]</p>
                        <p className="rigTxt">서울특별시 영등포구 여의공원로 111, 2층~8층 / 대표전화 : 02) 3787-6600</p>
                   </div>
                    <div className="mid">
                        <p className="lfTxt">한국청년기업가정신재단 [06595]</p>
                        <p className="rigTxt">서울특별시 서초구 서초대로45길 16, 202호 / 대표전화 : 02) 2156-2280</p>
                    </div>
                    <div className="bttom">
                        <p className="txt">COPYRIGHT &copy; BY ERNST &amp; YOUNG HAN YOUNG / KOREA</p>
                        <p className="txt">ENTREPRENEURSHIP FOUNDATION ALL RIGHTS RESERVED</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;