import React,{ useState, useEffect } from 'react';
import { Route,useHistory,Link, useLocation } from 'react-router-dom';
import { emailFilms, BootstrapInput } from './data';
import axios from 'axios';
import { TextField, FormControl, Autocomplete } from "@mui/material";

import './style.scss';

function TermDownload() {
    const history = useHistory();

    const init = {
        "emailId"    : '',
        "emailAddr"  : '',
    }
    const [data, setData] = useState(init);
    const handleData = (v, key) => {
        setData({...data, [key] : v});
    }

    const checkEmail = () => {
        const fulEmail = data?.emailId+"@"+data?.emailAddr;
        if(data?.emailId === '' || data?.emailAddr === '' ){
            alert("이메일을 입력해주세요.");
            return;
        }
        axios.post(`${process.env.REACT_APP_API_URL}` + '/request/chk', 
        {
            "email" : fulEmail
        },
        ).then(res => {
            if(res?.data.chk){
                history.push({
                    pathname: "/glossary/termdownload/download",
                    state: {"chk":true}
                }); 
            }else{
                alert("해당 이메일의 신청 내역이 존재하지않습니다.");
            } 
        }).catch()
    }

    const handleKeyPress = (e) => {
        if(e.key === 'Enter') {
            checkEmail();
        }
    }
    
    return(
        <div className="inner">
            <div className="termDownloadBox">
                <h1>소셜벤처 창업 현장 용어사전</h1>
                <p className="tit">소셜벤처 창업 현장 용어 해설 동영상 신청확인</p>
                <div className="reconfirmBox">
                    <p>기존에 신청했던 이메일 주소 입력을 통해 용어 해설 동영상을 재확인합니다.</p>
                    <div>
                        <span className="inputTit">E-mail</span>
                        <FormControl className="emailInput" sx={{ m: 1 }} variant="standard">
                            <BootstrapInput 
                                id="demo-customized-textbox"
                                value={data?.emailId}
                                onChange={(e)=>{handleData(e.target.value, "emailId")}}
                            />
                        </FormControl>
                        @
                        <Autocomplete
                            className="selectBox"
                            disablePortal
                            freeSolo
                            id="controllable-states-demo"
                            options={emailFilms}
                            onChange={(e)=>{
                                if(e.key === 'Enter') return;
                                handleData(e.target.innerText, "emailAddr");
                            }}
                            onInputChange={(e, v)=>{
                                if(e.key === 'Enter') return;
                                handleData(v, "emailAddr");
                            }}
                            sx={{ width: 300 }}
                            renderInput={(params) => <TextField {...params} />}
                            onKeyPress={handleKeyPress}
                           
                        />
                    </div>
                    <div onClick={()=>{checkEmail()}} className="reconfirmBtn">신청확인</div>
                </div>
                <div className="downloadPage">
                    <p className="tit">용어 해설 동영상 신청 페이지로 이동합니다.</p>
                    <div>
                        <div className="txt">
                            *성함, 이메일주소 등 개인정보제공이용동의 후 용어 해설 동영상 시청이 가능합니다.
                        </div>
                        <div  className="applyBtn" onClick={()=>{ history.push('/glossary/termdownload/application');}}>
                            신청하기
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TermDownload;
