import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import Pagination from 'react-js-pagination';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import './style.scss';

function List(props) {
  const history = useHistory();
  // 페이징 관련
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [totalcount, setTotalcount] = useState();

  const [list, setList] = useState();

  const viewListCnt = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}` + '/board/total', { categoreCd: '1' })
      .then((res) => {
        setTotalcount(res?.data.totalCnt);
        viewList(page);
      })
      .catch();
  };

  const viewList = (e) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}` + '/board/view', {
        page: e,
        perPage: perPage,
        categoreCd: '1',
      })
      .then((res) => {
        if (res?.data.list) {
          setList(res.data.list);
        }
      })
      .catch();
  };

  useEffect(() => {
    viewListCnt();
  }, []);

  return (
    <>
      <TableContainer className="noticeList">
        <Table sx={{ minWidth: 350 }} aria-label="simple table">
          <TableHead className="tableHead">
            <TableRow>
              <TableCell className="headTit" width="5%" align="left">
                No.
              </TableCell>
              <TableCell className="headTit" width="65%" align="left">
                제목
              </TableCell>
              <TableCell className="headTit" width="30%" align="center">
                작성일자
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ cursor: 'pointer' }}>
            {list &&
              list.map((obj, i) => {
                if (props.page === 'all' && i > 3) {
                  return false;
                }
                return (
                  <TableRow
                    key={obj.seq}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    onClick={() => {
                      history.push('/news/notice/' + obj.seq);
                    }}
                  >
                    <TableCell className="bodyTit" align="left">
                      {i + 1}
                    </TableCell>
                    <TableCell className="bodyTit" align="left">
                      {obj.title}
                    </TableCell>
                    <TableCell className="bodyTit" align="center">
                      {obj.creAt}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {props.page === 'all' ? null : (
        <div>
          {totalcount && totalcount > 8 ? (
            <>
              <br />
              <br />

              <Pagination
                activePage={page}
                itemsCountPerPage={perPage}
                totalItemsCount={totalcount}
                pageRangeDisplayed={5}
                prevPageText={'<'}
                nextPageText={'>'}
                firstPageText={'<<'}
                lastPageText={'>>'}
                onChange={(e) => {
                  setPage(e);
                  viewList(e);
                }}
              />
              <div className="noneBox_50"></div>
            </>
          ) : null}
        </div>
      )}
    </>
  );
}

export default List;
