import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import List from '../../Menus/News/Notice/List';
import { isMobile } from 'react-device-detect';
import axios from 'axios';

import { Select, FormControl, MenuItem, Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import './style.scss';

function Body() {
  const history = useHistory();

  const init = {
    type: 't',
    categoreSeq: '',
    keyword: '',
  };

  const [data, setData] = useState(init);
  const handleData = (v, key) => {
    setData({ ...data, [key]: v });
  };

  const [categoreList, setcategoreList] = useState();
  const categoreView = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}` + '/categore/view', {})
      .then((res) => {
        if (res?.data.list) {
          setcategoreList(res.data.list);
        }
      })
      .catch();
  };

  const searchPageMove = () => {
    history.push({
      pathname: '/glossary/termsearch',
      state: data,
    });
  };

  useEffect(() => {
    categoreView();
  }, []);

  return (
    <div className="inner">
      <div className="main">
        <div className="suchBarBox">
          <FormControl className="searchSelectBox" sx={{ m: 1 }}>
            <Select
              value={data?.type}
              onChange={(e) => {
                handleData(e.target.value, 'type');
              }}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              className="searchSelect"
            >
              <MenuItem style={isMobile ? { fontSize: '14px' } : {}} value="t">
                용어명
              </MenuItem>
              <MenuItem style={isMobile ? { fontSize: '14px' } : {}} value="c">
                내용
              </MenuItem>
              <MenuItem style={isMobile ? { fontSize: '14px' } : {}} value="a">
                용어명 + 내용
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl className="classificationBox" sx={{ m: 1 }}>
            <Select
              value={data?.categoreSeq}
              onChange={(e) => {
                handleData(e.target.value, 'categoreSeq');
              }}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              className="searchSelect"
            >
              <MenuItem style={isMobile ? { fontSize: '14px' } : {}} value="">
                전체 분류
              </MenuItem>
              {categoreList &&
                categoreList.map((obj, i) => {
                  return (
                    <MenuItem style={isMobile ? { fontSize: '14px' } : {}} key={obj.seq} value={obj.seq}>
                      {obj.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
          <div className="searchBox">
            <Box className="inputBox">
              <input
                className="searchInput"
                placeholder="용어검색"
                onChange={(e) => {
                  handleData(e.target.value, 'keyword');
                }}
                onKeyPress={(e) => {
                  if (e.key == 'Enter') {
                    searchPageMove();
                  }
                }}
              />
              <SearchIcon className="searchIcon" />
            </Box>
          </div>
          <span
            className="searchBtn"
            onClick={() => {
              searchPageMove();
            }}
          >
            검색
          </span>
        </div>
        <div
          className="imgBox"
          style={{ width: '100%', height: 'auto', display: 'inline-block', cursor: 'pointer' }}
          onClick={() => {
            history.push('/glossary/termdownload/application');
          }}
        >
          <img style={{ width: '100%', height: 'auto' }} src={isMobile ? '/images/mainMoImg.jpg' : '/images/mainImg.jpg'} />
        </div>
        <div className="infoTxtBox">
          <p className="tit">소셜벤처 창업 현장 용어사전</p>
          <div className="txtBox">
            어렵고 헷갈리기만 했던 용어를 한눈에!
            <br />
            문장으로 배우는 창업현장 용어사전(소셜벤처)편
          </div>
        </div>
        <div className="lfBox">
          <div className="termsDownloadBox">
            <p className="tit">용어 해설 동영상 보기</p>
            <div>
              <div className="txt">
                *성함, 이메일 주소 등 개인정보제공이용동의 후<br />
                용어 해설 동영상 시청이 가능합니다.
              </div>
            </div>
            <div
              className="applyBtn"
              onClick={() => {
                history.push('/glossary/termdownload/application');
              }}
            >
              신청하기
            </div>
          </div>
        </div>
        <div className="noticeListBox">
          <List page={'all'} />
          <div className="btmBox">
            <div
              className="totalListBox"
              onClick={() => {
                history.push('/news/question');
              }}
            >
              <span>문의하기</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Body;
