import React,{ useState, useEffect } from 'react';
import { Route,useHistory,Link, useLocation } from 'react-router-dom';
import {emailFilms, divisionFilms, BootstrapInput} from './data';
import { Checkbox, TextField, FormControl, Autocomplete } from "@mui/material";
import axios from 'axios';

import './style.scss'

function Application() {
    const history = useHistory();
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const init = {
        "companyName" : '',
        "name"        : '',
        "type"        : '',
        "emailId"     : '',
        "emailAddr"   : '',
        "agree1"      : false,
        "agree2"      : false,
    }
    const [data, setData] = useState(init);
    const handleData = (v, key) => {
        setData({...data, [key] : v});
    }

    const send = () => {
        if(!checkData()) return;
        const fulEmail = data?.emailId+"@"+data?.emailAddr;
        
        axios.post(`${process.env.REACT_APP_API_URL}` + '/request/insert', 
        {
            ...data, "email" : fulEmail
        },
        ).then(res => {
            if(res?.data?.msg){
                alert(res?.data?.msg);
                history.push({
                    pathname: "/glossary/termdownload/download",
                    state: {"chk":true}
                }); 
            }else{
                alert(res?.data?.err);
            }
        }).catch()
    }

    const checkData = () => {
        if(!data?.agree1){
            alert("상단 개인정보 수집·이용에 대한 동의를 체크해주세요.");
            return false;
        }
        if(!data?.agree2){
            alert("상단 제3자에 대한 위탁에 대한 동의를 체크해주세요.");
            return false;
        }
        if(data?.name === '' ){
            alert("성함을 입력해주세요.");
            return false;
        }
        if(data?.type === '' ){
            alert("구분을 선택 또는 입력해주세요.");
            return false;
        }
        if(data?.companyName === '' || data?.companyName === '' ){
            alert("기업명을 입력해주세요.\n기업에 해당하지 않는 경우 '해당없음'을 기입해주세요.");
            return false;
        }
        if(data?.emailId === '' || data?.emailAddr === '' ){
            alert("이메일을 입력해주세요.");
            return false;
        }
        return true;
    }

    const handleKeyPress = (e) => {
        if(e.key === 'Enter') {
            send();
        }
    }

    return(
        <div className="inner">
            <p className="title" style={{margin: '60px'}}>소셜벤처 창업 현장 용어사전</p>
            <div className="applicationBox">
                <div className="infoBox">
                    <p className="tit">소셜벤처 창업 현장 용어사전</p>
                    <div className="txtBox">
                        <p>소셜벤처 창업 현장 용어사전의 용어 해설 동영상을 시청하실 수 있습니다.</p>
                    </div>
                </div>
                <div className="privacyBox">
                        <p className="tit">개인정보 수집·이용 및 제3자 위탁·제공 동의서</p>
                        <div className="txtBox">
                            <p>
                                <p style={{fontWeight: '700'}}>&lt; 개인정보 수집·이용에 대한 동의 &gt;</p><br/>
                                한영회계법인과 재단법인 한국청년기업가정신재단은 「소셜벤처 창업 현장 용어사전(영상)」을 배포함에 있어 「개인정보 보호법」에 따라 <br/>
                                따라 아래와 같은 사항을 알리고 귀하의 동의하에 아래와 같은 귀하의 개인정보를 수집, 이용하고자 합니다. <br/>
                                「개인정보 보호법」에서 규정하고 있는 책임과 의무를 준수하고 있으며 귀하가 동의한 내용 외 다른 목적으로는 활용하지 않음을 알려드립니다.<br/><br/>
                                가. 개인정보를 수집·이용 기관 : 한영회계법인 및 재단법인 한국청년기업가정신재단<br/>
                                나. 개인정보 수집·이용 목적 : 사업성과관리(참여자 관리) 등<br/>
                                다. 개인정보 수집·이용 항목 : <br/>
                                &nbsp;&nbsp;1. 필수정보 : 성명, 신청자 구분(일반/창업자 등), 기업명, 이메일<br/>
                                &nbsp;&nbsp;2. 선택정보 : 없음<br/>
                                라. 개인정보 보유 및 이용 기간 : 수집·이용 동의일로부터 3년<br/>
                                마. 개인정보 수집근거 : 「개인정보 보호법」 제15조 제1항 및 제2항<br/>
                                바. 개인정보 수집·이용 동의 거부권 및 불이익 : 귀하는 개인정보 수집·이용을  거부할 수 있습니다. 다만, 개인정보 수집·이용에 관하여 동의를 거부하실 경우 「소셜벤처 창업 현장 용어사전(영상)」을 이용함에 제약이 있음을 알려드립니다.<br/>
                                <br/>
                                위 사항을 숙지하고 개인정보 수집·이용에 대하여 동의하십니까?
                                <br/>
                            </p>
                        </div>
                        <div className="ckBox">
                            동의합니다<Checkbox style={{color: '#005b8f'}} {...label} value={data?.agree1}
                            onChange={(e)=>{
                                handleData(e.target.value, "agree1");
                            }}/>
                        </div>
                        <hr/>
                        <div className="txtBox">
                            <p>
                                <p style={{fontWeight: '700'}}>&lt; 제3자에 대한 위탁에 대한 동의 &gt;</p><br/>
                                한국청년기업가정신재단은 「소셜벤처 창업 현장 용어사전(영상)」을 배포하며 제공받은 개인정보와 소셜벤처 창업 현장 용어사전 홈페이지의 <br/>
                                효율적 운영과 관리 등을 위해 귀하의 개인정보 관리를 외부 전문기관과 위탁계약을 체결하여 위탁할 수 있습니다. <br/>
                                신청 시 수집한 개인정보는 아래의 내용에 따라 위탁기관에 위탁하게 됩니다. <br/><br/>
                                가. 개인정보를 위탁받는 자 : 주식회사 인트윈<br/>
                                나. 처리위탁 업무내용 : 홈페이지 관리 및 이벤트 운영 등<br/>
                                다. 개인정보를 위탁하는 정보의 항목 : 성명, 신청자 구분(일반/창업자 등), 기업명, 이메일<br/>
                                라. 개인정보를 위탁받는 제3자의 개인정보 보유 및 이용 기간 : 신청일로부터 3년<br/>
                                마. 개인정보 위탁 일시 및 위탁 방법 : 2022.04.06. / 해당 서비스가 유지되는 기간 동안 홈페이지를 통하여 이전<br/>
                                바. 동의거부권 및 동의 거부에 대한 안내: 귀하는 위와 같이 수집하는 개인정보를 위탁하는 것에 대한 동의를 거부할 권리가 있습니다.<br/>
                                &nbsp;&nbsp;다만, 개인정보 위탁 동의를 거부하실 경우 「소셜벤처 창업 현장 용어사전(영상)」을 이용함에 제약이 있음을 알려드립니다.<br/>
                                <br/>
                                위 사항을 숙지하고 개인정보의 위탁에 동의하십니까?
                                <br/>
                            </p>
                        </div>
                        <div className="ckBox">
                            동의합니다<Checkbox style={{color: '#005b8f'}} {...label} value={data?.agree2}
                            onChange={(e)=>{
                                handleData(e.target.value, "agree2");
                            }}/>
                        </div>
                    </div>
                <div className="privacyInfoBox">
                    <div className="inputListBox">
                        <div>
                            <span className="inputTit">성&nbsp;함</span>
                            <FormControl className="nameInput" style={{marginLeft: '25px', verticalAlign: '14px'}} sx={{ m: 1 }} variant="standard">
                                <BootstrapInput id="demo-customized-textbox" 
                                    value={data?.name}
                                    onChange={(e)=>{handleData(e.target.value, "name")}}
                                />
                            </FormControl>
                        </div>
                        <div className="companyInputBox" style={{width: '100%'}}>
                            <span className="inputTit" style={{verticalAlign: '2px'}}>구&nbsp;분</span>
                            <Autocomplete
                                className="divisionSelectBox"
                                disablePortal
                                freeSolo
                                id="free-solo-2-demo"
                                options={divisionFilms}
                                onChange={(e)=>{handleData(e.target.innerText, "type")}}
                                onInputChange={(e, v)=>{handleData(v, "type")}}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                            <span style={{verticalAlign: '2px'}} className="companyInputTit">기업명</span>
                            <FormControl className="companyBox" sx={{ m: 1 }} variant="standard">
                                <BootstrapInput id="demo-customized-textbox" 
                                    value={data?.companyName}
                                    onChange={(e)=>{handleData(e.target.value, "companyName")}}
                                />
                            </FormControl>
                        </div>
                        <span className="inputTit emailTit" style={{verticalAlign: '2px'}}>E-mail</span>
                        <FormControl className="emailInput" sx={{ m: 1 }} variant="standard">
                            <BootstrapInput id="demo-customized-textbox" 
                                value={data?.emailId}
                                onChange={(e)=>{handleData(e.target.value, "emailId")}}
                            />
                        </FormControl>
                        @
                        <Autocomplete
                            className="selectBox"
                            disablePortal
                            freeSolo
                            id="free-solo-2-demo"
                            onChange={(e)=>{
                                if(e.key === 'Enter') return;
                                handleData(e.target.innerText, "emailAddr");
                            }}
                            onInputChange={(e, v)=>{
                                if(e.key === 'Enter') return;
                                handleData(v, "emailAddr");
                            }}
                            value={data?.emailAddr}
                            options={emailFilms}
                            sx={{ width: 300 }}
                            renderInput={(params) => <TextField {...params} />}
                            onKeyPress={handleKeyPress}
                        />
                    </div>
                    <div className="completeBtn"  onClick={()=>{send()}}>신청완료</div>
                </div>
            </div>
        </div>
    )
}

export default Application;