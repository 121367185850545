import { styled } from '@mui/material/styles';
import { InputBase } from "@mui/material";

export const emailFilms = [
    { id: 0, label: '직접입력' },
    { id: 1, label: 'naver.com'},
    { id: 2, label: 'gmail.com'},
    { id: 3, label: 'daum.net'},
    { id: 4, label: 'hanmail.net'},
];
export const divisionFilms = [
    { id: 0, label: '직접입력' },
    { id: 1, label: '일반'},
    { id: 2, label: '예비창업자'},
    { id: 3, label: '일반기업'},
    { id: 4, label: '소셜벤처/사회적기업'},
    { id: 5, label: '기타'},
];

export const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #e0e0e0',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#e0e0e0',
      },
    },
}));