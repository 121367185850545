import React,{ useState, useEffect } from 'react';
import { useParams,useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux'
import LeftBar from '../index';
import List from '../Notice/List';
import Detail from '../Notice/Detail';

import './style.scss';

function Notice() {
    const {pk} = useParams();
    const history = useHistory();

    return (
        <>
        <LeftBar actPage={"공지사항"}/> 
            <div className="inner">     
                <div className="noticeBox">
                    <h2>공지사항</h2>
                    <div className="noticeListBox">
                        { pk
                        ? <Detail pk={pk}/>
                        : <List/>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default Notice;