export const menus = [
    { seq: 0, name: "소셜벤처 창업 현장 용어사전 소개", path: "/introduction/0"},
    { seq: 2, name: "EY한영 소개", path: "/introduction/2"},
    { seq: 1, name: "한국청년기업가정신재단 소개", path: "/introduction/1"},
    // { seq: 3, name: "용어사전 도움말", path: "/introduction/3"}
];
export const menusImg = [
    { seq: 0, src: "/images/introductionImg_1.jpg"},
    { seq: 1, src: "/images/introductionImg_2.jpg"},
    { seq: 2, src: "/images/introductionImg_3.jpg"},
    { seq: 3, src: "/images/introductionImg_4.jpg"}
];
export const menusMoImg = [
    { seq: 0, src: "/images/introductionMoImg_1.jpg"},
    { seq: 1, src: "/images/introductionMoImg_2.jpg"},
    { seq: 2, src: "/images/introductionMoImg_3.jpg"},
    { seq: 3, src: "/images/introductionMoImg_4.jpg"}
];