import React,{ useState, useEffect } from 'react';
import { useHistory,useLocation } from 'react-router-dom';
import axios from 'axios';
import {korWordData, engWordData} from './data';
import {isMobile} from "react-device-detect";
import Pagination from "react-js-pagination";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
    Select,
    MenuItem,
    FormControl,
    Stack,
    Box
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';

import './style.scss';
import { display } from '@mui/system';

function TermSearch() {
    const history = useHistory();
    const location = useLocation();

    // 페이징 관련
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(5);
    const [totalcount, setTotalcount] = useState();

    const init = {
        "type"        : location?.state?.type ? location?.state?.type : 't',
        "keyword"     : location?.state?.keyword ? location?.state?.keyword : '',
        "categoreSeq" : location?.state?.categoreSeq ? location?.state?.categoreSeq : '',
        "consonant"   : '',
        "hideYn"      : 'N',
    }

    const [data, setData] = useState(init);
    const handleData = (v, key) => {
        setData({...data, [key] : v});
    }
    
    const [list, setList] = useState();
    const viewCnt = (c) => {
        axios.post(`${process.env.REACT_APP_API_URL}` + '/glossary/total', 
        {   ...data, "keyword" : data.keyword != '' ? "%"+data.keyword+"%" : null,
            "consonant" : c != 'All' ? c : null,
        }).then(res => {
            setTotalcount(res.data.totalCount);
            setPage(1);
            view(1,c);
        }).catch()
    }
    const view = (e,c) => {
        axios.post(`${process.env.REACT_APP_API_URL}` + '/glossary/view',
        {   ...data, "keyword" : data.keyword != '' ? "%"+data.keyword+"%" : null,
            "consonant" : c != 'All' ? c : null,
            "page"      : e,
            "perPage"   : perPage,
        }).then(res => {
            if(res?.data.list){
                setList(res.data.list);
            }
        }).catch()
    }

    const [categoreList, setcategoreList] = useState();
    const categoreView = () => {
        axios.post(`${process.env.REACT_APP_API_URL}` + '/categore/view',
        {}).then(res => {
            if(res?.data.list){
                setcategoreList(res.data.list);
                console.log(res.data.list);
            }
        }).catch()
    }

    useEffect(() => {
        viewCnt();
        categoreView();
    }, []);

    return(
        <div className="inner">
            <div className="termsListBox">
                <h1>소셜벤처 창업 현장 용어사전</h1>
                <div className="termSearchBox">
                    <div>
                        <FormControl className="searchSelectBox" sx={{ m: 1}}>
                            <Select
                                value={data?.type}
                                onChange={(e)=>{handleData(e.target.value, "type")}}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                className="searchSelect"
                            >
                                <MenuItem style={isMobile ? {fontSize:"14px"}: {}} value="t">용어명</MenuItem>
                                <MenuItem style={isMobile ? {fontSize:"14px"}: {}} className="menuItem" value="c">내용</MenuItem>
                                <MenuItem style={isMobile ? {fontSize:"14px"}: {}} value="a">용어명 + 내용</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl className="classificationBox" sx={{ m: 1}}>
                            <Select
                                value={data?.categoreSeq}
                                onChange={(e)=>{handleData(e.target.value, "categoreSeq")}}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                className="searchSelect"
                            >
                                <MenuItem style={isMobile ? {fontSize:"14px"}: {}} value="">전체 분류</MenuItem>
                                {categoreList && categoreList.map((obj, i) => {
                                    return (
                                        <MenuItem style={isMobile ? {fontSize:"14px"}: {}} key={obj.seq} value={obj.seq}>{obj.name}</MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        <div className="searchBox">
                            <Box className="inputBox">
                                <input className="searchInput" placeholder="용어검색" 
                                    value={data?.keyword}
                                    onChange={(e)=>{handleData(e.target.value, "keyword")}}
                                    onKeyPress={(e) => {
                                        if(e.key == 'Enter'){
                                            viewCnt();
                                        }
                                    }}
                                /> 
                                <SearchIcon className="searchIcon"/> 
                            </Box>
                        </div>
                        <span className="searchBtn" onClick={()=>{
                            view(page,"All");
                        }}>검색</span>
                    </div>
                    <div className="wordList">
                        {/* <p className="all on">전체보기(ALL)</p> */}
                        <p className="wordTit">찾고자 하는 용어의 자음 또는 알파벳을 입력해도 가능합니다.</p>
                        <div className="consonantBox">
                            <div className="korWord">
                                {korWordData && korWordData.map((obj, i) => {
                                    return (
                                        <p style={{fontSize: '20px'}} key={obj.id} className="word"onClick={()=>{
                                            viewCnt(obj.word);
                                            handleData(obj.word, "consonant");
                                        }}>{obj.word}</p>
                                    );
                                })}
                            </div>
                            <div className="engWord">
                                {engWordData && engWordData.map((obj, i) => {
                                    return (
                                        <p key={obj.id} className="word" onClick={()=>{
                                            viewCnt(obj.word);
                                            handleData(obj.word, "consonant");
                                        }}>{obj.word}</p>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="termListBox">
                        {list && list.map((obj, i) => {
                            return (
                                <div key={obj.seq} className="termList" onClick={()=>{
                                    history.push("/glossary/termsearch/"+obj.seq);
                                }}>
                                    <div className="wordBox"><span>{obj.consonant}</span></div>
                                    <p className="tit">{obj.name}</p>
                                    <div className="txt">
                                        {obj.categoreNm}
                                    </div>
                                </div>
                            );
                        })}
                        <div className="noneBox_70"></div>
                       
                        {!list || list.length === 0
                        ? <><div  className='noneBox_100'>
                                <div className="imgBox" style={{marginTop:"150px"}}>
                                    <img src="/images/questionIcon.png" style={{width: '90px'}}/>
                                </div>
                              <h3 style={{marginTop: "35px"}}>검색된 결과가 없습니다.</h3>
                          </div>
                          <div className='noneBox_60'>
                          </div></>
                        : 
                        <>
                         { totalcount && totalcount > 5
                        ? 
                            <Pagination 
                                className="paginationBox"
                                activePage={page} 
                                itemsCountPerPage={perPage} 
                                totalItemsCount={totalcount} 
                                pageRangeDisplayed={5} 
                                prevPageText={"〈"} 
                                nextPageText={"〉"} 
                                firstPageText={"<<"}
                                lastPageText={">>"}
                                onChange={ (e) => {
                                    setPage(e);
                                    view(e, data?.consonant);
                                }} 
                            />
                        : null}
                        </>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TermSearch;