import React,{ useState, useEffect } from 'react';
import { Route,useHistory,Link, useLocation } from 'react-router-dom';
import { menus } from './data';

import './style.scss';

function News(props) {
    const history = useHistory();

    return(
        <div className="news">
            <div className="sidebar">
                <h1>소식 / 문의</h1>
                <div className="sideBox">
                    {menus && menus.map((obj, i) => {
                        return (
                            <Link key={obj.seq} className={props.actPage === obj.name ? "menusTit on" : "menusTit"} to={obj.path} >{obj.name}</Link>
                        );
                    })}
                </div>
            </div>
        </div>
    )
}

export default News;