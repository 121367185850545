import React,{ useState } from 'react';
import Loader from '../../../utils/Loader';
import axios from 'axios';
import LeftBar from '../index';
import { TextField } from "@mui/material";

import './style.scss';

function Question() {
    const [loading, setLoading] = useState(false);
    const init = {
        "title"   : '',
        "email"   : '',
        "content" : '',
    }
    const [data, setData] = useState(init);
    const handleData = (v, key) => {
        setData({...data, [key] : v});
    }

    const send = () => {
        if(!onCheckData()) return;
        setLoading(true);
        axios.post(`${process.env.REACT_APP_API_URL}` + '/question/send',
        {...data}).then(res => {
            alert(res?.data.msg);
            setLoading(false);
        }).catch()
    }

    const onCheckData = () => {
        if(data.title === '' || data.title === null || data.title === undefined){
            alert("제목을 입력해 주세요.");
            return false;
        }
        if(data.email === '' || data.email === null || data.email === undefined){
            alert("발신 메일주소를 입력해 주세요.");
            return false;
        }
        const mailReg = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
        if( !mailReg.test(data.email) ) {
            alert("이메일을 형식에 맞게 입력해주세요.");
            return false;
        }
        if(data.content === '' || data.content === null || data.content === undefined){
            alert("문의내용을 입력해 주세요.");
            return false;
        }
        return true;
    }

    return (
        <>
            <LeftBar actPage={"문의하기"} />
            <div className="inner">
                <Loader loading={loading} />
                <div className="queBox">
                    <h2>문의하기</h2>
                    <div className="queMainBox">
                        <div className='inputBox'>
                            <h5>문의 메일 전송</h5>
                            <TextField className='titleInput' label="문의 제목" variant="outlined" onChange={(e)=>{handleData(e.target.value,"title");}}/>
                            <TextField className='emailInput' label="발신 메일주소" variant="outlined" onChange={(e)=>{handleData(e.target.value,"email");}}/>
                            <textarea className='contentTex' placeholder="문의 내용 작성" onChange={(e)=>{handleData(e.target.value,"content");}}/>
                            <div className="sendBtn" onClick={send}>문의 메일 전송하기</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Question;