import { styled } from '@mui/material/styles';
import { InputBase } from "@mui/material";

export const emailFilms = [
    { val : '',            label: '직접입력' },
    { val : 'naver.com',   label: 'naver.com'},
    { val : 'gmail.com',   label: 'gmail.com'},
    { val : 'daum.net',    label: 'daum.net'},
    { val : 'hanmail.net', label: 'hanmail.net'},
];

export const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #e0e0e0',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#e0e0e0',
      },
    },
}));