import React,{ useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    AppBar,
    Box,
    Button,
    Container,
    IconButton,
    Toolbar,
    Typography,
    MenuItem ,
    Menu
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

import './style.scss';

function Header() {
    const history = useHistory();
    
    const [mMenuDepth, setmMenuDepth] = useState(false);
    const [menuItemBg, setMenuItemBg] = useState();
    const [twoD, setMTwoD] = useState();

    const handleClose= (e) => {
        setMenuItemBg('none');
        setMTwoD('none');
    }
    const handleOpen= (e) => {
        setMenuItemBg('block');
        setMTwoD('block');
    }

    return (
        <>
            <AppBar className="appBar" position="static">
                <Container style={{padding: '0'}} className="inner" maxWidth="xl">
                    <Toolbar className="headerLf" disableGutters>
                        <Typography className="logo"
                            style={{cursor: 'pointer'}}
                            variant="h6"
                            noWrap
                            component="div"
                            style={{marginLeft: '20px'}}
                            sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
                            onClick={()=>{ history.push('/');}}
                        >
                            <img src="/images/logo.png"/>
                        </Typography>
                        <div className="mMenuBtn" onClick={()=>{setmMenuDepth(!mMenuDepth)}}>
                            <>
                                { mMenuDepth !== true
                                    ?<MenuIcon style={{color: '#fff'}}/>
                                    :<CloseIcon style={{color: '#fff'}}/>
                                }
                            </>

                        </div>
                        {
                            mMenuDepth === true
                                ?<div className="mBox">
                                    <p className="mOneD" onClick={()=>{ history.push('/introduction/0');setmMenuDepth(!mMenuDepth);}}>
                                        소개
                                    </p>
                                    <p className="mOneD" onClick={()=>{ history.push('/glossary/termsearch');setmMenuDepth(!mMenuDepth);}}>
                                        용어사전
                                    </p>
                                    <p className="mOneD" onClick={()=>{ history.push('/glossary/termdownload');setmMenuDepth(!mMenuDepth);}}>
                                        해설영상
                                    </p>
                                    <p className="mOneD" onClick={()=>{ history.push('/glossary/termdownload/pdfdownload');setmMenuDepth(!mMenuDepth);}}>
                                        용어사전(PDF)
                                    </p>
                                    <p className="mOneD" onClick={()=>{ history.push('/news/notice');setmMenuDepth(!mMenuDepth);}}>
                                        소식 / 문의
                                    </p>
                                </div>
                                : null
                            }
                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, cursor: 'pointer' }}
                            onClick={()=>{ history.push('/');}}
                            className="mLogo"
                        >   
                            <img src="/images/logo.png"/>
                        </Typography>
                        <Box onMouseLeave={handleClose} className="menuBox" sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex'} }}>
                            <div className="menuItemBg" onClick={handleClose} style={{display: menuItemBg}}></div>
                            <Button className="oneD"  onMouseEnter={handleOpen} sx={{ my: 2, color: 'white', display: 'block' }}>
                                <p onClick={()=>{ history.push('/introduction/0');}}>소개</p>
                                <ul className="twoD" style={{display: twoD}}>
                                    <li onClick={()=>{ 
                                        history.push('/introduction/0');
                                        handleClose();
                                        }}>용어사전 소개</li>
                                    <li onClick={()=>{ 
                                        history.push('/introduction/1');
                                        handleClose();
                                        }}>재단 소개</li>
                                    <li onClick={()=>{ 
                                        history.push('/introduction/2');
                                        handleClose();
                                    }}>EY한영 소개</li>
                                    {/* <li onClick={()=>{ 
                                        history.push('/introduction/3');
                                        handleClose();}
                                    }>용어사전 도움말</li> */}
                                </ul>
                            </Button>
                            <Button className="oneD" onMouseEnter={handleOpen} sx={{ my: 2, color: 'white', display: 'block' }}>
                                <p onClick={()=>{ history.push('/glossary/termsearch');}}>용어사전</p>
                                <ul className="twoD" style={{display: twoD}}>
                                    <li onClick={()=>{ 
                                        history.push('/glossary/termsearch');
                                        handleClose();
                                        }}>용어 검색</li>
                                    <li onClick={()=>{ 
                                        history.push('/glossary/termdownload');
                                        handleClose();
                                        }}>해설영상</li>    
                                    <li onClick={()=>{ 
                                        history.push('/glossary/termdownload/pdfdownload');
                                        handleClose();
                                        }}>용어사전(PDF)</li>
                                </ul>
                            </Button>
                            <Button className="oneD" onMouseEnter={handleOpen} sx={{ my: 2, color: 'white', display: 'block' }}>
                                <p onClick={()=>{ 
                                    history.push('/news/notice');
                                    handleClose();
                                }}>소식 / 문의</p>
                                <ul className="twoD" style={{display: twoD}}>
                                    <li onClick={()=>{ 
                                        history.push('/news/notice');
                                        handleClose();
                                    }}>공지사항</li>
                                    <li onClick={()=>{ 
                                        history.push('/news/question');
                                        handleClose();
                                    }}>문의하기</li>
                                </ul>
                            </Button>
                        </Box>
                    </Toolbar>
                    {/* <div className="headerRig">
                        <img className="img"/>
                        X
                        <img className="img"/>
                    </div> */}
                </Container>
            </AppBar>
        </>
    );
}

export default Header;
