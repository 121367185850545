import React,{ useState, useEffect } from 'react';
import { useHistory,useParams } from 'react-router-dom';
import axios from 'axios';

import './style.scss';

function Detail(props) {
    const history = useHistory();
    const {pk} = useParams();
    const [data, setData] = useState();
        
    const view = () => {
        axios.post(`${process.env.REACT_APP_API_URL}` + '/glossary/view',
        { 'key' : pk,
        }).then(res => {
            if(res?.data.list){
                setData(res?.data.list[0]);
            }
        }).catch()
    }

    useEffect(() => {
        view();
    }, []);

    return (
        <div className="inner">
            <div className="termsDetailBox">
                <h1>소셜벤처 창업 현장 용어사전</h1>
                <div className="mainBox">
                    <div key={data?.seq} className="termList">
                        <div className="wordBox"><span>{data?.consonant}</span></div>
                        <p className="tit">{data?.name}</p>
                        <div className="txt">
                            {data?.categoreNm}
                        </div>
                        <hr/>
                        <div className="termContact" 
                            dangerouslySetInnerHTML={{__html: data?.content}}>
                        </div>
                    </div>
                    <div className="backBtn" onClick={()=>{
                        history.goBack();
                    }}>
                        목록으로 돌아가기
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Detail;